<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
      <!-- <img
        :src="require('@/page/namvaytien/components/img/logo.png')"
        :alt="logo"
        class="logoImg"
      /> -->
      <span class="title">MINH LỘC MẠNH CO, LTD</span>
    </div>
    <ul class="nav-links">
      <li
        :class="{ 'active-link': isActive('/') }" class="Li1">
        <router-link to="/" class="nav-link1">
          <span class="routeText1">Chúng ta là ai</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
        <router-link to="/about" class="nav-link2">
          <span class="routeText2">Dịch vụ của chúng tôi</span>
        </router-link>
      </li>
      <li
        :class="{ 'active-link': $route.path === '/question' }" class="Li3">
        <router-link to="/question" class="nav-link3">
          <span class="routeText3">Vấn đề thường gặp</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
        <router-link to="/protocol" class="nav-link4">
          <span class="routeText4">Thỏa thuận quyền riêng tư</span>
        </router-link>
      </li>
    </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "@/page/namvaytien/components/img/Rectangle.png",
      link1Color: "white", 
    };
  },
  methods: {
   
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "";
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 80px; */
  padding: 0 15px;
  background-color: #950000;
  /* box-shadow: 0 2px #682F1C; */
}


.logo {
  font-weight: bold;
  color: black;
  display: flex; /* 让 Logo 内容成为一个 flex 容器，使图片和文字水平排列 */
  align-items: center; /* 垂直居中对齐 */
  padding-left: 10px;
}

.logoImg {
  width: 50px;
  height: 50px;
}
.title {
  width: 430px;
  height: 24px;
  font-size: 16px;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 23px;
  padding-left: 16px;
}
.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 60px;
  /* text-transform: uppercase; */
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  /* padding: 6px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 72px;
  /* padding-left: 4px;
  padding-right: 4px; */
  /* border: 1px solid red; */
}
.Li1 {
  width: 150px;
}
.Li2 {
  width: 200px;
}
.Li3 {
  width: 170px;
}
.Li4 {
  width: 220px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.nav-links li {
  background-size: 15% 10%;
}
.active-link {
  background: url("@/page/mangomoneyapp/components/img/Group1.png");
  /* background-color: #EBF7FF; */
  background-size: 100% 100%; /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
  background-position: 50% 0;
}
.active-link .routeText {
  color: white;
}
.active-link span{
  color: #FFFFFF;
  font-weight: 600;
}
.title{
  /* border: 1px solid red; */
  padding-left: 100px;
}
</style>